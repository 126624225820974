<template>
    <div class="wrapper">

        <div class="modal_title">{{ $t('delete_account_popup.title_delete_account_popup') }}</div>


        <div class="text1">
            {{ $t('delete_account_popup.text_1') }}
        </div>

        <div class="text2">
            {{ $t('delete_account_popup.text_2') }}
        </div>

        <div class="confirm flex">
            <CheckboxSwitch v-model="valid" :name="'aaa'"/>
            <span>{{ $t('delete_account_popup.yes') }}</span>
        </div>

        <div class="button_wrap">
            <button class="base-button" :disabled="!valid || isLoading" @click="submit">
                <LoaderAnimation v-if="isLoading"/>
                {{ $t('delete_account_popup.btn') }}
            </button>
        </div>


    </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {mapGetters, mapMutations} from "vuex";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";
import CheckboxSwitch from "@/components/common/CheckboxSwitch";

export default {
    name: "DeleteAccountModal",
    components: {
        LoaderAnimation,
        CheckboxSwitch
    },
    setup() {
        const {t} = useI18n({useScope: 'global'});
        return {
            t,
        }
    },

    data() {
        return {
            isLoading: false,

            valid: false,
            successful: false,

        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
        }),

    },

    methods: {
        ...mapMutations({
            setUser: 'user/setUser',
        }),
        submit() {
            this.isLoading = true;
            this.$store.dispatch('user/delete_account', {})
                .then((data) => {
                    console.log(data);
                    //this.setUser(data.data);
                    this.$store.commit('popups/openPopup', {
                        name: 'DefaultModal',
                        props: {
                            text: this.$t('delete_account_popup.success_result'),
                            reload_page: true,
                        }
                    })
                })
                .catch(e => {
                    console.log(e);
                    this.$store.commit('popups/openPopup', {
                        name: 'DefaultModal',
                        props: {text: this.$t('delete_account_popup.failed_result')}
                    })
                })
                .finally(()=>{
                    this.isLoading = false
                });
        },
    },
    mounted() {


    },
}
</script>


<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;

.text1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    margin-bottom: 10px;
}

.text2 {
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
    color: #7e7e7e;
    margin-bottom: 20px;
}


.confirm {
    gap: 10px;
    align-items: center;
    span{
        font-weight: 700;
    }
}


.button_wrap {
    margin-top: 30px;
    display: flex;
    justify-content: right;
}

.successful {
    .base-button {
        margin-top: 30px;
    }
}

</style>
