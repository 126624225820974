import en from './en.json'
import ru from './ru.json'
import fr from './fr.json'

export const defaultLocale = 'en'

export const languages = {
    en,
    ru,
    fr,
}
