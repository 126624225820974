import axios from "@/utils/axios";

export default {
    namespaced: true,
    state: () => ({
        transactions: {
            main: [],
            partner: []
        },
        courses: null
    }),
    getters: {
        allowedWithdrawalByRule200(state) {
            const allTransactions = state.transactions.main.concat(state.transactions.partner);
            //console.log('all',allTransactions)
            const sumOfExpenses = allTransactions.filter((el) => {
                return el.reason === 'packet_buy'
            }).map((el) => {
                return Math.abs(el.sum)
            })
                .reduce((sum, el) => {
                    return sum + el
                },0);
            //console.log('sum',sumOfExpenses)
            return sumOfExpenses >= 100;
        },
        partnerWithdrawTransactionsFreeze(state){
            if (!state.transactions.partner.length) return [];
            return state.transactions.partner.filter(el=>
                {
                    return el.reason ==='freeze'
                }
            )
        }


    },
    mutations: {
        setTransactions(state, list) {
            state.transactions = list;
        },
        setCourses(state, courses) {
            state.courses = courses;
        }
    },
    actions: {
        //!!!!!!!!!!!!!!!!!!!!!!!! ОТКЛЮЧЕНО - ПЕРЕДЕЛАТЬ !!!!!!!!!!!!
        /* add({commit},balance){
             return new Promise((resolve, reject) => {


                 axios.post('/api/balance/add',{sum:balance}).then(response => {
                     let balance = response.data.balance;
                     commit('user/setBalance', balance,{root:true});
                     resolve(response);
                 }).catch(error => reject(error));

             });
         },*/

        getTransactions({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/transactions/get/', {params:payload}).then(response => {
                    commit('setTransactions', response.data);
                    resolve(response);
                }).catch(error => reject(error));
            });
        },

        getCourses({commit}) {
            return new Promise((resolve, reject) => {
                axios.get('/api/v1/info/courses/get/').then(({data}) => {
                    commit('setCourses', data.courses);
                    resolve(data);
                }).catch(error => reject(error));
            });
        },

        exchangePartnerToMain({commit}, payload) {
            return new Promise((resolve, reject) => {
                    axios.post('/api/v1/transfer/partner_to_main', {amount: payload}).then(({data}) => {
                        commit('user/changePartnerBalance', -1 * payload, {root: true})
                        resolve(data);
                    })
                    .catch((error) => {
                        reject(error);
                    });

                }
            )
        }

    }
}
