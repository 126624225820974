<template>
    <div class="wrapper">
        <div class="modal_title">
            {{ $t('edit_wallet_popup.title_edit_wallet_popup') }}
        </div>

        <div>
            <p>
                {{ $t('edit_wallet_popup.text_1') }}
            </p>
            <p>
                {{ $t('edit_wallet_popup.text_2') }}
            </p>
            <p>
                {{ $t('edit_wallet_popup.your_current_wallet') }} <span
                :class="{'red-alert':!wallet}">{{ wallet ? wallet : this.$t('edit_wallet_popup.not_added') }}</span>
            </p>

            <TextField v-model="newWallet"
                       :class_input="'gray'"
                       :label="this.$t('edit_wallet_popup.label_new_wallet')"
                       :readonly="isLoading"
            ></TextField>
            <p v-if="errorWallet" class="form_error">{{ errorWallet }}</p>

            <div class="need_password">
                <p class="modal__text">{{ $t('edit_wallet_popup.text_enter_password') }}</p>
                <TextField v-model="password"
                           :type="'password'"
                           :class_wrap="'need_password'"
                           :class_input="'gray'"
                           :label="$t('common.field_password')"
                           :autocomplete="'off'"
                           :readonly="isLoading"
                ></TextField>
                <p v-if="errorPassword" class="form_error">{{ errorPassword }}</p>
            </div>
            <div class="d-flex jb">
                <button  @click="back" v-if="wallet&&source" class="base-button gray">
                    {{ $t('common.back') }}
                </button>
                <button class="base-button submit_btn"
                        @click="requestChangeWallet"
                        :disabled="buttonDisabled"
                >{{ $t('common.send') }}
                </button>
            </div>

            <div class="form_error">{{ errorMessage }}</div>

        </div>


    </div>
</template>


<script>

import TextField from '@/components/common/TextField.vue'
import {mapGetters} from "vuex";

export default {
    name: "EditWalletModal",
    components: {
        TextField
    },

    data() {
        return {
            newWallet: '',
            password: '',
            errorPassword: '',
            errorMessage: '',
            errorWallet: '',
            isLoading: false,
        }
    },
    props: {
        source: {
            default: '',
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/userGetter',
            wallet: 'user/walletGetter',
        }),
        validationWallet() {
            const regex = new RegExp('T[A-Za-z1-9]{33}');
            return regex.test(this.newWallet);
        },
        buttonDisabled() {
            return (this.newWallet.length < 1 || this.password.length < 1 || this.isLoading);
        }
    },
    methods: {
        back(){
            this.$store.commit('popups/openPopup', {name: this.source})
        },
        requestChangeWallet() {
            this.errorPassword = '';
            this.errorMessage = '';
            this.errorWallet = '';

            if (!this.validationWallet) {
                this.errorWallet = this.$t('edit_wallet_popup.error_wallet');
            } else {
                this.isLoading = true;
                this.$store.dispatch('user/fetchChangeWallet', {
                    wallet: this.newWallet,
                    password: this.password
                }).then(() => {
                        if (!this.source)
                            this.$store.commit('popups/openPopup', {
                                name: 'DefaultModal',
                                props: {text: this.$t('edit_wallet_popup.wallet_successfully')}
                            })
                        else
                            this.back();

                    }
                )
                    .catch((error) => {
                        console.error('error', error.response);
                        if (error.response.data.error === 'wrong password') {
                            this.errorPassword = this.$t('common.wrong_password')
                        }
                        if (error.response.data.errors.wallet.includes('The wallet has already been taken.')){
                            this.errorWallet = this.$t('edit_wallet_popup.wallet_used')
                        }


                    }).finally(
                    this.isLoading = false
                )
            }
        }
    },
    mounted(){
        if (this.wallet) {
            this.$store.commit('popups/openPopup', {
                name: 'DefaultModal', props: {text: this.$t('edit_wallet_popup.change_not_available')}

            });
        }
    }

}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;
@import "./src/styles/form.scss";

p {
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
}

.need_password {
    margin-top: 20px;
}

.submit_btn {
    margin-top: 30px;
    width: 300px;
    float: right;
}

.successful {
    margin-top: 10px;
}

.btn_close {
    width: 300px;
    margin: 40px auto 0;
    display: block;
}

</style>
