<template>

    <input class="checkbox_switch" type="checkbox" v-model="checked" :id="name_" >
    <label :for="name_" ></label>

</template>


<script>
export default {
    name: "CheckboxSwitch",
    props:{
        name: {
            type: String,
            // default: 'id'+parseInt(Math.random()*10000).toString(),
            default: '',
        },
        modelValue: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            checked: this.modelValue,
        }
    },
    computed:{
        name_(){
            return this.name === '' ? 'id'+parseInt(Math.random()*10000).toString() : this.name;
        }
    },
    watch: {
        checked(){
            this.$emit('update:modelValue', this.checked);
        }
    },
}

</script>

<style lang="scss" scoped>

.checkbox_switch {
    display: none;
}
.checkbox_switch+label::before {
    display: none;
}
.checkbox_switch+label, .checkbox_switch+label::before, .checkbox_switch+label::after {
    transition: all .3s;
}
.checkbox_switch+label {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 30px;
    border-radius: 16px;
    cursor: pointer;
    background: #ccc;
    padding: 0;
    transition: all .1s ease;
}
.checkbox_switch+label::after {
    border-radius: 50%;
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 22px;
    height: 22px;
    background: white;
    box-shadow: 3px 0 10px #919599;
    transition: all .1s ease;
}
.checkbox_switch:checked+label {
    background: #3E76B5;
}
.checkbox_switch:checked+label::after {
    content: "";
    position: absolute;
    transform: none;
    top: 4px;
    left: auto;
    right: 5px;
    width: 22px;
    height: 22px;
    background: white;
    box-shadow: -3px 0px 10px #234980;
    transition: all .1s ease;
}

</style>
