<template>
    <div class="wrapper">
        <div class="modal_title">
            {{ $t('edit_email_popup.title_edit_email_popup') }}
        </div>

        <div v-if="!successful">

            <p v-if="user.email_verified_at == null">
                {{ $t('edit_email_popup.text_2') }}
            </p>

            <p v-else>
                {{ $t('edit_email_popup.text_1') }}
            </p>

            <p>
                {{ $t('edit_email_popup.your_current_address') }} {{email}}
            </p>

            <TextField v-model="newEmail"
                       :class_wrap="isEmailValidClass()"
                       :class_input="'gray'"
                       :type="'email'"
                       :label="this.$t('edit_email_popup.label_new_email')"
                       :readonly="codeSended"
            ></TextField>

            <div class="need_password">
                <p class="modal__text">{{ $t('edit_email_popup.text_enter_password') }}</p>
                <TextField v-model="password"
                           :type="'password'"
                           :class_wrap="'need_password'"
                           :class_input="'gray'"
                           :label=" $t('common.field_password')"
                           :autocomplete="'off'"
                           :readonly="codeSended"
                ></TextField>
                <p class="form_error">{{ errorPassword }}</p>
            </div>

            <div class="confirm_fields " v-if="confirm">
                <p class="modal__text">{{ $t('edit_email_popup.text_code') }} {{email}}</p>
                <TextField v-model="confirmCode"
                           :class_input="'gray'"
                           :label="this.$t('edit_email_popup.label_code')"
                           :autocomplete="'off'"
                ></TextField>
                <p class="form_error">{{ errorConfirmCode }}</p>
            </div>


            <button class="base-button submit_btn"
                    @click="changeUserData"
                    :disabled="buttonDisable"
            >Отправить</button >

            <div class="form_error">{{errorMessage}}</div>

        </div>


        <div class="successful" v-if="successful">
            <p>{{ $t('edit_email_popup.email_successfully') }}</p>
            <button class="base-button btn_close" @click="$emit('close')">{{ $t('common.close') }}</button>
        </div>


    </div>
</template>


<script>
import { useI18n } from "vue-i18n";
import TextField from '@/components/common/TextField.vue'

import {mapGetters, mapMutations} from "vuex";

export default {
    name: "EditEmailModal",
    components: {
        TextField
    },
    setup(){
        const {t} = useI18n({useScope: 'global'});
        return {t};
    },
    data() {
        return {
            email: '',
            newEmail: '',
            needPassword: false,
            password: '',
            codeSended: false,
            confirm: false,
            confirmCode: '',
            errorPassword: '',
            errorConfirmCode: '',
            errorMessage: '',
            buttonDisable: true,
            successful: false,
        }
    },
    computed:{
        ...mapGetters({
            user:'user/userGetter',
        })
    },
    created() {
        this.email = this.user.email;
    },
    watch: {
        newEmail(){
            this.buttonDisable = !(this.isEmailValid() && this.password !== '');
        },
        password(){
            this.buttonDisable = !(this.isEmailValid() && this.password !== '');
        },
        confirmCode(){
            this.buttonDisable = this.confirmCode === '';
            this.errorConfirmCode = '';
        }
    },
    methods: {
        ...mapMutations({
            setUserData: 'user/setUserData',
        }),
        isEmailValidClass(){
            return this.isEmailValid() ? 'field_success' : 'field_error';
        },
        isEmailValid() {
            // const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
            // const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
            const EMAIL_REGEXP = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/;
            return (this.newEmail === "")? "" : (EMAIL_REGEXP.test(this.newEmail));
        },
        changeUserData() {
            this.errorPassword = '';
            this.errorMessage = '';

            if (this.user.email_verified_at == null){
                this.changeEmail();
            }else{

                if (!this.codeSended) {  // step1
                    this.changeInit();
                }else{  // step2
                    this.changeConfirm();
                }
            }

        },
        changeEmail(){
            this.buttonDisable = true;
            this.$store.dispatch('user/changeEmail', {email: this.newEmail, password: this.password}).then((data) => {
                console.log('data', data);
                this.successful = true;
            }).catch(e => {
                if (e.response && e.response.data) {
                    if (e.response.data.error === 'wrong password') {
                        this.errorPassword = this.t('common.wrong_password');
                    }
                    if (e.response.data.message === 'The given data was invalid.') {
                        if (e.response.data.errors.email[0] === "The email has already been taken."){
                            this.errorMessage = this.$t('edit_email_popup.error_data_email_has_been_taken');
                        }else{
                            this.errorMessage = this.$t('edit_email_popup.error_data');
                        }
                    }
                }
            });
        },
        changeInit(){
            this.buttonDisable = true;
            this.$store.dispatch('user/changeEmailInit', {email: this.newEmail, password: this.password}).then(() => {
                //console.log('data', data);
                this.confirm = true;
                this.codeSended = true;
            }).catch(e => {
                if (e.response && e.response.data) {
                    if (e.response.data.error === 'wrong password') {
                        this.errorPassword = this.t('common.wrong_password');
                    }
                    if (e.response.data.message === 'The given data was invalid.') {
                        if (e.response.data.errors.email[0] === "The email has already been taken."){
                            this.errorMessage = this.$t('edit_email_popup.error_data_email_has_been_taken');
                        }else{
                            this.errorMessage = this.$t('edit_email_popup.error_data');
                        }
                    }
                }
            });
        },
        changeConfirm(){
            this.buttonDisable = true;
            this.$store.dispatch('user/changeEmailConfirm', {code: this.confirmCode}).then(() => {
                //console.log('data', data);
                this.successful = true;
                this.setUserData({name:'email', value: this.newEmail});
            }).catch(e => {
                if (e.response && e.response.data) {
                    if (e.response.data.status === 'error') {
                        this.errorConfirmCode = this.$t('edit_email_popup.error_code');
                    }
                    if (e.response.data.message === 'The given data was invalid.') {
                        this.errorMessage = this.$t('edit_email_popup.error_data');
                    }
                }
            });
        },


    },
}
</script>

<style lang="scss" scoped>
@use "~@/styles/mixins.scss" as *;
@import "./src/styles/form.scss";

p{
    margin-bottom: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 140%;
}

.need_password{
    margin-top: 20px;
}
.submit_btn{
    margin-top: 30px;
    width: 300px;
    float: right;
}

.successful {
    margin-top: 10px;
}
.btn_close{
    width: 300px;
    margin: 40px auto 0;
    display: block;
}

</style>
