<template>
    <div class="wrapper">
      <!--:class = "{active:currentUser.id===user.id,}"-->
        <div class="modal_title">Структура</div>

        <div class="structure d-flex">
            <div class="structure_head d-flex">
                <div class="structure__col" :class="'structure__col_'+i" v-for="(col,i) in structure.length" :key="'c'+i">
                    {{ $t('partners_team_structure_page.line') }} {{ i }}
                </div>
            </div>
            <div class="structure__col" v-for="(col,i) in structure" :key="'c'+i">
                <div class="structure__user"
                     v-for="user in col" :key="user.id"
                     @click="currentUser = user"
                     :class="{active:currentUser&&currentUser.id===user.id,
                    relative:currentUser&&(currentUser.referral_user_id === user.id||user.referral_user_id===currentUser.id)}">
                    <div class="structure__user-name">{{user.first_name+' '+user.second_name+' '+user.third_name}}</div>
                    <div class="structure__user-stats">{{getStatusName(user.level_id)+', '+user.ref_count+' партнеров'}}</div>
                    <TriangleDown v-if="user.ref_count"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import TriangleDown from "@/assets/icons/TriangleDown.vue";
import {mapState} from "vuex";
export default {
    name: "StructureModal",
    components: {TriangleDown},
    props:{
      structure:Array,
      currentId:Number
    },
    data() {
        return {
          currentUser:null
        }
    },
    computed:{
      ...mapState({
        statuses: state => state.planes.statuses
      })
    },
    methods:{
      getStatusName(level){
        if (!this.statuses) return 'Уровень '+level;
        const status =  this.statuses.find(el=>el.id===level);
        return status?status.name:'Уровень '+level;
      },
    },
    mounted(){
      //console.log(this.structure);
      this.structure.forEach(col=>{
        col.forEach(user=>{
          if (user.id===this.currentId) this.currentUser = user;
        })
      });
    }
}
</script>



<style lang="scss" scoped>
.structure{
    overflow:auto;
    position: relative;
    background: #fff;
    padding: 130px 60px 15px 0;
    border-radius: 16px 0 0 16px;
    width: calc(100% + 60px);
    &__col{
        flex: 0 0 auto;
        padding: 0 12px;
        width: 28%;
        margin-left: -1px;
        min-width: 250px;
        &+&{
            border-left: 1px solid #E3EAED;
        }
        overflow:auto;
        &::-webkit-scrollbar {
            width: 2px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #BAC5C8;;
        }
    }
    &__user{
        position: relative;
        margin-bottom: 5px;
        cursor:pointer;
        border-radius: 16px;
        padding: 10px 20px 10px 10px;
        color: rgba(46, 51, 56, 0.7);
        font-weight: 500;
        &-name{
            font-size: 14px;
        }
        &-stats{
            font-size:12px;
            margin-top:2px;
        }
        &>svg{
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translateY(-50%) scale(1.5) rotate(90deg);
            &::v-deep path{
                stroke:#BAC5C8;
            }
        }
        &.active{
            color:#fff;
            background: #3E76B5;
            .structure__user-name{
                font-weight: 700;
            }
        }
        &.relative{
            background: #EBEFF1;
            .structure__user-name{
                color:#232528;
                font-weight: 700;
            }
        }
    }
    .next_pay {
        font-size: 14px;
    }
    .date {

    }
    .date.orange {
        color: #f0aa29;
    }
    .date.red {
        color: #cf3232;
    }
    .active .date.red {
        background: #ffffffa6;
        padding: 0 2px;
    }

    .more_info{
        display: none;
        flex-direction: column;
        font-size: 14px;
        padding: 8px 0;
        color: #222;
        line-height: 25px;
        &>span {
            display: flex;
            align-items: center;
            gap: 4px;
            svg{
                height: 10px;
                width: 12px;
            }
        }
    }
    .active .more_info{
        display: flex;
        color: #fff;
    }

}


.structure__col_0 {
    position: absolute;
    top: 15px;
    .more_info {
        display: none!important;
    }
    .next_pay{
        display: none;
    }
}

.structure_head {
    position: absolute;
    width: 100%;
    left: 0;
    padding: 0 48px 0px 12px;
    margin-top: -39px;
    .structure__col{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        background: #e3eaed;
        padding: 2px;
    }
    .structure__col_0 {
        display: none;
    }
}

@media (max-width: 768px) {
    .structure {
        border-radius: 0;
        padding-right: 15px;
        width: calc(100% + 30px);
        margin-left: -15px;
    }
    .structure_head {
        padding: 0 0 0 12px;
    }
}

</style>
