import axios from "@/utils/axios";


export default {
    namespaced: true,
    state: () => ({
        teamList: [],
        teamStructure: [],
        searchQuery: '',
        filterLevel: '',
        filterStatus: '',
    }),
    getters: {
        searchedList(state) {
            // return state.teamList.filter(teamList => teamList.full_name.toLowerCase().includes(state.searchQuery.toLowerCase()))
            return state.teamList
                .filter(teamList => teamList.level_name.includes(state.filterStatus))
                .filter(teamList => teamList.full_name.toLowerCase().includes(state.searchQuery.toLowerCase()));
        },
        filteredList(state){
            return state.teamList;
        }
    },
    mutations: {
        setTeam(state, list){
            state.teamList = list;
        },
        setTeamStructure(state, list){
            state.teamStructure = list;
        },
        setSearchQuery(state, searchQuery) {
            if (typeof searchQuery === "object"){
                state.searchQuery = searchQuery.target.value
            }else{
                state.searchQuery = searchQuery;
            }
        },
        setFilterLevel(state, level) {
            if (typeof level === "object"){
                state.filterLevel = level.target.value
            }else{
                state.filterLevel = level;
            }
        },
        setFilterStatus(state, status) {
            if (typeof status === "object"){
                state.filterStatus = status.target.value
            }else{
                state.filterStatus = status;
            }
        },
    },
    actions: {
        // getTeam({commit}){
        //     return new Promise((resolve, reject) => {
        //         axios.get('/api/v1/team/get/').then(({data}) => {
        //             commit('setTeam', data);
        //             resolve(data);
        //         }).catch(error => reject(error));
        //     });
        // },

        async getTeam({commit}) {
            try {
                const response = await axios.get('/api/v1/team/get/');
                response.data.forEach(function (item, index){
                    response.data[index].full_name = response.data[index].second_name + ' ' + response.data[index].first_name;
                    if (item.level_name == null){
                        response.data[index].level_name = 'Client'
                    }
                });
                //console.log('team',response.data);
                commit('setTeam', response.data)
            } catch (e) {
                console.log(e)
            } finally {
                //
            }
        },


        async getStructure({commit}) {
            try {
                const response = await axios.get('/api/v1/team/get_structure/');
                commit('setTeamStructure', response.data)
            } catch (e) {
                console.log(e)
            } finally {
                //
            }
        },



    }
}
