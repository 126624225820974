import { createApp } from "vue/dist/vue.esm-bundler";
import App from './App.vue'
import router from './router'
import store from './store'
import VueJivosite from '@bchteam/vue-jivosite'
//import axios, { AxiosInstance } from 'axios'
import axios from 'axios'
// import {UniversalSocialauth} from 'universal-social-auth'
import VueCookies from 'vue-cookies'

import { plugin, defaultConfig } from '@formkit/vue'
import { ru } from '@formkit/i18n'

import { createI18n, useI18n } from "vue-i18n";
import { languages } from './i18n'
import { defaultLocale } from './i18n'

import VueYandexMetrika from 'vue3-yandex-metrika'



let locale = defaultLocale;

if (VueCookies.get('lang') !== null){
    locale = VueCookies.get('lang');
}else if (navigator.language.includes('ru')) {
    locale = 'ru';
    VueCookies.set('lang', locale, 60 * 60 * 24 * 1, '/');
}
else if (navigator.language.includes('en')) {
    locale = 'en';
    VueCookies.set('lang', locale, 60 * 60 * 24 * 1, '/');
}else{
    VueCookies.set('lang', locale, 60 * 60 * 24 * 1, '/');
}
// else if (navigator.language.includes('de')) {
//    locale = 'de';
//}

const messages = Object.assign(languages)
const i18n = createI18n({
    legacy: false,
    // locale: localStorage.getItem('lang'),  /// localStorage.getItem('lang') || defaultLocale,
    locale,
    fallbackLocale: 'ru',
    messages,
})

/*
`Vue3` declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $axios: AxiosInstance;
        $Oauth:UniversalSocialauth;

    }
}

 */

// const options = {
//     providers: {
//         // apple: {
//         //   nonce: '**************',
//         //   state: '**************',
//         //   clientId: '**************',
//         //   redirectUri: 'https://myapp.com/auth/github/callback'
//         // },
//         github: {
//             clientId: 'ae23f55f36664062ff8c',
//             redirectUri: 'http://localhost:8080/auth/github/callback'
//         },
//         twitch: {
//             clientId: 'qi4vbk30uuvuqigcd5ioq3egxf67m8',
//             redirectUri: 'https://diadal.com.ng/auth/twitch/callback'
//         },
//         // google: {
//         //   clientId: '***************',
//         //   redirectUri: 'https://myapp.com/auth/google/callback'
//         // },
//         // facebook: {
//         //   clientId: '************',
//         //   redirectUri: 'https://myapp.com/auth/facebook/callback'
//         // },
//         // twitter: {
//         //   url: 'https://myapp.com/auth/twitter',
//         //   clientId: '********',
//         //   redirectUri: 'https://myapp.com/auth/twitter/callback'
//         // }
//     }
// }

const app = createApp(App, {
        setup(){
            const {t} = useI18n()
            return {t}
        }
    })
    .use(i18n)
    .use(store)
    .use(router)

app.use(VueYandexMetrika, {
    id: 93384249,
    router: router,
    //env: process.env.NODE_ENV
    env: 'production',
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    }
})
.use(plugin, defaultConfig({
        // Define additional locales
        locales: { ru },
        // Define the active locale
        locale: 'ru',
    }
))
.use(VueJivosite, { widgetId: '9yOa3N2nQE' })
// const Oauth = new UniversalSocialauth(axios, options);
// console.log('oauth-', Oauth);
//
// app.config.globalProperties.$Oauth = Oauth
app.config.globalProperties.$axios = axios
app.config.globalProperties.$setTitle = (title = 'El-atom investors area') => {
    document.title = title;
}
app.mount('#app');



