<template>
    <div class="wrapper">
        <div class="modal_title">
            {{$t('transferModals.p2pTransferMain')}}
        </div>
        <div class="p2p__description-partner">
            {{$t('transferModals.mainAccount')}}
        </div>
        <div class="p2p__description-available">
            {{$t('transferModals.available')}}: {{ mainBalance }}$
        </div>
        <div class="p2p__description-arrow">
            <ArrowToBottom/>
        </div>
        <div class="p2p__description-main">
            {{ userName + ' ' + userSecondName }}
        </div>


        <div class="p2p__amount">
            <input type="number" step="1" min="0" v-model="amount" placeholder="0$"/>
            <div v-if="errorMessage" class="red-alert error-amount">{{ errorMessage }}</div>
        </div>
        <div class="p2p__send">
            <button class="base-button" :disabled="buttonDisabled" @click="sendToMain">
                <LoaderAnimation v-if="isLoading"/>
                {{$t('transferModals.transfer')}}
            </button>
        </div>
        <div class="p2p__decline">
            <div class="cancel" @click="close"> {{ $t('common.decline') }}</div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import ArrowToBottom from "@/assets/icons/ArrowToBottom";
import LoaderAnimation from "@/assets/animations/LoaderAnimation";

export default {
    name: "MainToMainSend",
    components: {LoaderAnimation, ArrowToBottom},
    data() {
        return {
            amount: '',
            isLoading: false,
            errorMessage: '',
        }
    },
    props: {
        userName: {
            default: ''
        },
        userSecondName: {
            default: ''
        },
        id: {
            default: 0,
        }

    },
    computed: {
        ...mapGetters({
           mainBalance: 'user/balanceGetter',
        }),
        buttonDisabled() {
            // return true;
            return (this.isLoading || this.amount <= 0);
        },
    },
    methods: {
        close() {
            if (this.isLoading) return;
            this.$emit('close');
        },
        sendToMain() {

            this.errorMessage = '';

            if (this.isLoading) return;

            if (this.amount > this.partnerBalance) {
                return this.errorMessage = this.$t('transferModals.insufficient')
            }
            this.isLoading = true;

            this.$store.dispatch('user/sendMainToMain', {
                    amount: this.amount,
                    partner_id: this.id
                }).then(() => {
                    this.$store.dispatch('balance/getTransactions');
                    this.$store.commit('popups/openPopup', {
                        name: 'DefaultModal',
                        props: {text: this.amount + '$ ' + this.$t('transferModals.p2pSuccess') + this.userName + ' ' + this.userSecondName}
                    })

                })
                .catch((error) => this.errorMessage = error.response.data.error)
                .finally(() => {

                    this.isLoading = false;
                });


        }
    }
}
</script>

<style lang="scss" scoped>
.p2p {
    &__description {
        &-partner {
            text-align: center;
            font-size: 18px;
            font-weight: 500;
        }

        &-send {
            width: 100%;
            margin-bottom: 30px;

            .base-button {
                width: 100%;
            }

        }

        &-available {
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            color: #3E76B5;
        }

        &-decline {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #BAC5C8;
            text-decoration: underline;

        }

        &-arrow {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
        }

        &-main {
            font-size: 18px;
            font-weight: 500;
            text-align: center;
            margin-bottom: 30px;
        }
    }

    &__amount {
        margin-bottom: 30px;
        position: relative;

        input {
            width: 100%;
            position: relative;
            background: #EBEFF1;
            border-radius: 16px;
            border: none;
            outline: none;
            padding: 18px 30px 18px;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
        }

        .error-amount {

            position: absolute;
            top: 100%;
            left: 0;
            max-width: 100%;
        }

    }

    &__send {
        width: 100%;

        .base-button {
            width: 100%;
        }

    }

    &__decline {
        .cancel {
            margin-top: 10px;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            color: #BAC5C8;
            text-align: center;
            text-decoration: underline;
        }
    }

}
</style>
