<template>
    <div v-if="user">
        <PopupWrapper/>
        <MainMenu :allowPage="allowPage"/>
        <div class="content-container">
            <div class="content d-flex fc">
                <transition name="fademain" mode="out-in">
                    <div :key="$route.name.split('.')[0]">

                        <router-view v-if="isAllowedPage"></router-view>

                        <div v-else>
                            {{ $t('common.access_denied') }}
                        </div>

                    </div>
                </transition>
                <MainFooter/>
            </div>
        </div>
    </div>
</template>

<script>
import MainMenu from "@/components/mainMenu/MainMenu";
import MainFooter from "@/components/mainFooter/mainFooter";
import {mapState, mapActions, mapGetters} from "vuex";
import PopupWrapper from "@/components/popups/PopupWrapper";


export default {
    name: "DashboardLayout",
    components: {PopupWrapper, MainFooter, MainMenu},
    data() {
        return {
            intervalFunction: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.data,
        }),
        ...mapGetters({
            verifyStatus: 'user/getVerificationStatus'
        }),
        allowPage() {
            if (this.user.email_verified_at == null){
                return [
                    'home',
                    'profile',
                    'news',
                    'about',
                    'contacts',
                    'community',
                ]
            }else{
                return [];
            }
        },
        isAllowedPage() {
            if (this.allowPage.length === 0) {
                return true;
            }
            // Проверяем, начинается ли имя текущего маршрута с одного из разрешённых имён
            return this.allowPage.some(page =>
                this.$route.name === page || this.$route.name.startsWith(`${page}.`)
            );
        },
    },
    methods: {
        ...mapActions({
            incrementVisits: 'referral/incrementVisits'
        }),
        handleResize(){
            this.$store.commit('screen/setWidth', window.innerWidth);
        }

    },
    created() {
        const refLink = this.$route.query.ref;
        if (refLink) {
            this.incrementVisits(refLink);
            window.localStorage.setItem('ref-link', refLink);
        }

        if (!this.user) {
            this.$store.dispatch('user/authentificate').then(() => {
                this.$store.dispatch('balance/getTransactions');
            }).finally(() => {
                if (this.user === null) {
                    this.$router.push({name: 'login'});
                }
            });

        }

        if (this.verifyStatus === 'pending') {
            this.intervalFunction = setInterval(() => {
                this.$store.dispatch('user/checkVerificationStatus').then((data) => {
                    if (data.data === 'not_verified' || data.data === 'verified') {
                        this.$store.dispatch('user/authentificate').then(this.$store.commit('popups/openPopup', {
                            name: 'DefaultModal',
                            props: {text: 'Ваш статус верификации был изменен!'}
                        }));
                    }
                })
            }, 5 * 60 * 1000)
        }

    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
        clearInterval(this.intervalFunction);
    }

}
</script>

<style scoped>

</style>
