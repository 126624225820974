<template>
<!--    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--        <g clip-path="url(#clip0_9293_27802)">-->
<!--            <path d="M13.0002 0C15.6084 0.000655956 18.1562 0.786477 20.3123 2.25533C22.4684 3.72418 24.1331 5.80813 25.0902 8.23634H0.910156C1.86719 5.80813 3.53194 3.72418 5.68805 2.25533C7.84415 0.786477 10.3919 0.000655956 13.0002 0V0Z" fill="#EBEFF1"/>-->
<!--            <path d="M25.09 8.23633C25.6889 9.75663 25.9975 11.3758 26 13.01C25.9986 14.6378 25.6898 16.2506 25.09 17.7637H0.91C0.310151 16.2506 0.00140036 14.6378 0 13.01C0.0024662 11.3758 0.311134 9.75663 0.91 8.23633H25.09Z" fill="#3E76B5"/>-->
<!--            <path d="M0.910156 17.7637C1.84278 20.1441 3.4556 22.1967 5.54713 23.6651C7.63866 25.1335 10.1162 25.9526 12.6702 26.02H13.3302C15.8818 25.9542 18.3577 25.1381 20.449 23.6734C22.5403 22.2087 24.1544 20.1603 25.0902 17.7837L0.910156 17.7637Z" fill="#FF3D00"/>-->
<!--        </g>-->
<!--        <defs>-->
<!--            <clipPath id="clip0_9293_27802">-->
<!--                <rect width="26" height="26" fill="white"/>-->
<!--            </clipPath>-->
<!--        </defs>-->
<!--    </svg>-->



    <svg  width="26" height="26"  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 20 20" style="enable-background:new 0 0 20 20;" xml:space="preserve">
        <g>
	<path class="st0" d="M13.2,1.6c-0.2-0.1-0.5-0.2-0.7-0.2c0,0,0,0-0.1,0c-0.2-0.1-0.5-0.1-0.7-0.2c-0.1,0-0.2,0-0.2,0
		c-0.2,0-0.4-0.1-0.6-0.1c-0.3,0-0.6,0-0.9,0S9.4,1,9.1,1c-0.2,0-0.4,0-0.6,0.1c-0.1,0-0.2,0-0.2,0c-0.2,0-0.5,0.1-0.7,0.2
		c0,0,0,0-0.1,0C7.3,1.4,7.1,1.5,6.8,1.6c0,0,0,0,0,0v17c0,0,0,0,0,0c0.2,0.1,0.5,0.2,0.7,0.2c0,0,0,0,0.1,0
		c0.2,0.1,0.5,0.1,0.7,0.2c0.1,0,0.2,0,0.2,0C8.8,19,9,19,9.1,19.1c0.3,0,0.6,0,0.9,0s0.6,0,0.9,0c0.2,0,0.4,0,0.6-0.1
		c0.1,0,0.2,0,0.2,0c0.2,0,0.5-0.1,0.7-0.2c0,0,0,0,0.1,0c0.3-0.1,0.5-0.2,0.7-0.2c0,0,0,0,0,0L13.2,1.6C13.2,1.6,13.2,1.6,13.2,1.6
		z"/>
            <path class="st1" d="M0.9,10.1c0,3.9,2.5,7.2,5.9,8.5v-17C3.4,2.9,0.9,6.2,0.9,10.1z"/>
            <path class="st2" d="M19,10.1c0-3.9-2.5-7.2-5.9-8.5v17C16.6,17.2,19,13.9,19,10.1z"/>
</g>
</svg>

</template>

<script>
export default {
    name: "Fr-LangIcon"
}
</script>

<style scoped>
    .st0{fill:#FFFFFF;}
    .st1{fill:#003DA5;}
    .st2{fill:#951914;}
</style>
